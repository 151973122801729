/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {


	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var siteLogo = $('.site-logo'),
		hamburger = $('.hamburger'),
		headerHeight = ( siteLogo.outerHeight() > hamburger.outerHeight() ) ? siteLogo.outerHeight() : hamburger.outerHeight(),
		menuButton = $('.menu-button');

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		smoothScroll();

		// Adjust margin for inner page or page without header images
		
		if ( $('body').hasClass('no-header-images') ) {
			$('#content > .container').css( 'padding-top', headerHeight );
		}

		if ( $('body').hasClass('inner-header') ) {
			$('#content > .container').css( 'padding-top', headerHeight + 60 );
		}

		// Intro Animations
		$('#page').addClass( 'init' );
		

		setTimeout(function() {
			$('.header_img_container').addClass('scale');
		}, 1800);
		
		

				/* GSAP Animations - Begin */

		// Decorations
		var decors = gsap.utils.toArray('.decor');

		if ( decors.length > 0 ) {
			decors.forEach((decor) => {
				gsap.to(
					decor,
					{
						duration: 4,
						xPercent: 'random(-10, -5)',
						yPercent: 'random(-15, -5)',
						scrollTrigger: {
							trigger: decor,
							scrub: 1,
						}
					}
				);
			});
		}

		// Menu
		var menu = gsap.timeline({
			paused: true,
			onReverseComplete: function() {
				submenus.forEach((submenu, index) => {
					tween[index].reverse();
				});
			}
		});

		menu.to('.main-cta', {
			duration: 0.5,
			opacity: 0,
			ease: 'Power2.inOut',
			onComplete() {
				$('.main-cta').addClass('menu_open')
			}
		}).to('.menu-blend', {
			duration: 0.5,
			translateX: '0%',
			opacity: 1,
			ease: 'Power2.inOut',
			stagger: 0.1
		}).to('#menu-offcanvas', {
			duration: 1,
			translateX: '0%',
			opacity: 1,
			ease: 'Power2.inOut',
			stagger: 0.1
		}).to('.main-cta', {
			duration: 0.5,
			opacity: 1,
			ease: 'Power2.inOut',
			onReverseComplete() {
				$('.main-cta').removeClass('menu_open')
			}
		}).to('.menu-item', {
			duration: 1,
			opacity: 1,
			ease: 'Power2.inOut',
			stagger: 0.1
		}, '-=0.1').to('.menu-bottom', {
			duration: 0.2,
			opacity: 1,
			ease: 'Power2.inOut'
		});

		// Sub Menus
		var submenus = gsap.utils.toArray('.sub-menu'),
			tween = [];

		submenus.forEach((submenu, index) => {
			tween.push(
				gsap.from(
					submenu,
					{
						duration: 0.25,
						height: 0,
						paused: true,
						reversed: true,
						ease: 'Power2.inOut'
					}
				)
			)
		});

		// Single letter animation
		function createTextAnimations() {
			$('.letter-animation').each(function(index) {
				let triggerElement = $(this);
				let targetElement = $(this).find('span');
			
				let tl = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElement,
						start: 'top bottom',
						end: 'bottom top',
					}
				});

				tl.from(targetElement, {
					duration: 0.6,
					ease: 'Circ.easeOut',
					yPercent: 80,
					stagger: {
						amount: 0.7,
						from: '0'
					}
				});
			});
		}

		createTextAnimations();

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		if ( titles.length > 0 ) {
			titles.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 1.5,
						yPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start,
						}
					}
				);
			});
		}

		
		//image slide reveal
		var reveal_containers = gsap.utils.toArray('.reveal-clip');

		reveal_containers.forEach((container) => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'center-=18% bottom'
				}
			});

			tl.fromTo(
				container,
				{
					clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
				},
				{
					duration: 2,
					clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
					ease: 'Power2.easeOut'
				}
			);
		});


		//image scale reveal
		var reveal_containers = gsap.utils.toArray('.reveal-scale');

		reveal_containers.forEach((container) => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'center bottom',
				}
			});

			tl.fromTo(
				container,
				{
					clipPath: 'ellipse(25% 40% at 50% 50%)',
				},
				{
					duration: 1.5,
					clipPath: 'ellipse(50% 50% at 50% 50%)',
					ease: 'Power2.easeOut',
				}
			);
		});


		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		if ( texts.length > 0 ) {
			texts.forEach((text) => {
				var delay = text.getAttribute('data-delay'),
					scroll = text.getAttribute('data-scroll'),
					start = 'top bottom';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					text,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: start,
						}
					}
				);
			});
		}

		// Extra words (discover)
		var extra = $('#extra');

		if ( extra.length > 0 ) {
			var items = gsap.utils.toArray('.split span');

			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.split',
					start: "bottom bottom",
				}
			});

			items.forEach((item) => {
				tl.to(
					item,
					{
						duration: 0.4,
						ease: 'none',
						opacity: 1
					}
				);
			});
		}

		/* GSAP Animations - End */

		// Activate/Deactivate sub-menus

		let submenus_triggers = $('#primary-menu').find('a[href="#"]')

		submenus_triggers.each(function(i) {
			$(this).on('click', function(e) {
				e.preventDefault();

				submenus_triggers.each(function(j) {
					if (i != j)
						!tween[j].reversed() && tween[j].reverse();
				})

				tween[i].reversed() ? tween[i].play() : tween[i].reverse();
			});
		});

		// Hide/show menu off canvas

		hamburger.on('click', function(e) {
			e.preventDefault();
			
			menuButton.toggleClass('collapsed');

			if ( ! menuButton.hasClass('collapsed') ) {
				menu.play();
			} else {
				submenus_triggers.each(function(i) {
					!tween[i].reversed() && tween[i].reverse();
				})

				menu.reverse();
			}
		});

	});

	document.addEventListener('DOMContentLoaded', function() {
		// Initialize Swiper
		const swiper = new Swiper('.swiper-container', {
			loop: true,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
			},

    		// Use slide or fade transition effect
    		speed: 2000,
    		effect: 'fade',
    		fadeEffect: {
    		      crossFade: true,
    		},
		});
	});
	

	// Code to run when the browser window has been resized

	var timer_id;

	$( window ).on( 'resize', function() {

		clearTimeout(timer_id);
		timer_id = setTimeout(function() {

			if ( $(window).width() >= 1024 ) {
				// Adjust margin for inner page or page without header images

				if ( $('body').hasClass('no-header-images') ) {
					$('#content > .container').css( 'padding-top', headerHeight );
				}
		
				if ( $('body').hasClass('inner-header') ) {
					$('#content > .container').css( 'padding-top', headerHeight + 60 );
				}
			}
			
		}, 300);

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Gallery slideshow

const gallerySlider = new Swiper('#gallery .mobile-gallery', {
	effect: "coverflow",
	centeredSlides: true,
	coverflowEffect: {
		rotate: 0,
		stretch: 0,
		modifier: 2.5,
		slideShadows: false
	},
	loop: true,
	grabCursor: true,
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: true,
	touchRatio: 1,
	autoplay: {
		disableOnInteraction: false,
		pauseOnMouseEnter: false,
		delay: 2500,
	},
	breakpoints: {
		540: {
			slidesPerView: 1.5,
			speed: 2000,
			centeredSlides: true,
		}
	},
});

// Reviews slideshow

const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	loop: true,
	slidesPerView: 1,
	centeredSlides: true,
	allowTouchMove: false,
	speed: 600,
	autoplay: false,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
	breakpoints: {
		768: {
			slidesPerView: 'auto',
			speed: 10000,
			autoplay: {
				delay: 1,
			}
		}
	},
});

/* Swiper - End */

// Lightbox effects

const lightbox = GLightbox({
	selector: '.glightbox',
	touchNavigation: true,
	loop: true,
	closeButton: true,
	svg: {
		close: '<svg viewBox="0 0 20 20"><path fill="currentColor" d="M10 8.585l4.293-4.292 1.414 1.414L11.414 10l4.293 4.293-1.414 1.414L10 11.414l-4.293 4.293-1.414-1.414L8.585 10 4.293 5.707l1.414-1.414L10 8.585z"></path></svg>',
		next: '<svg viewBox="0 0 20 20"><path fill="currentColor" d="M12 10l-7-7 1.414-1.414L14.828 10l-8.414 8.414L5 17z"></path></svg>',
		prev: '<svg viewBox="0 0 20 20"><path fill="currentColor" d="M8 10l7 7-1.414 1.414L4.172 10l8.414-8.414L15 3z"></path></svg>'
	}
});

